<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('con/schedule/access', 'read')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body v-if="is.initialised">

		<app-content-box :readonly="$authorised('con/schedule/access', 'read')">

			<app-input-text label="Name" placeholder="Enter name..." v-model="model.name" :validation="$v.model.name" />
			<app-input-checklist :locked="!isNew && item.count.active" :collapsed="!isNew && !item.count.active" :fullWidth="true" :singular="true" :columns="1" label="Sale" v-model="model.purchase.enabled" :validation="$v.model.purchase.enabled" :options="$constants.schedule.ticket.purchaseName" :descriptions="$constants.schedule.ticket.purchaseDescription" />
			<app-input-checklist :locked="!isNew && item.count.active" :collapsed="!isNew && !item.count.active" :fullWidth="true" :singular="true" :columns="1" label="Scanning" v-model="model.scope" :validation="$v.model.scope" :options="$constants.schedule.ticket.scopeName" :descriptions="$constants.schedule.ticket.scopeDescription" />
			<app-input-text label="Max per badge" :toggle="model.limit.enabled" v-on:toggle="model.limit.enabled = model.limit.enabled ? 0 : 1" v-model="model.limit.amount" :validation="$v.model.limit.amount" placeholder="Enter limit" notes="How many of this ticket type can a single user obtain." />
			<app-input-text label="Capacity" :toggle="model.capacity.enabled" v-on:toggle="model.capacity.enabled = model.capacity.enabled ? 0 : 1" v-model="model.capacity.amount" :validation="$v.model.capacity.amount" placeholder="Enter limit" notes="What is the maximum number of this ticket type that can be obtained during the convention." />
			
		</app-content-box>

		<app-content-section v-if="!isNew" route="Convention.Schedule.Ticket.Holders" :params="{ ticket: model.id }" icon="user" title="Tickets" description="Manage the holders of this ticket type." :count="item.count.active" :forceCount="true" />
		<app-content-section v-if="!isNew && model.purchase.enabled" :danger="!item.count.items" route="Convention.Registration.Items" :params="{ filter: { ticket: [item.id] } }"  :icon="(item.count.items) ? 'registration' : 'warning'" title="Items" :description="(item.count.items) ? 'Manage the purchasable items for this ticket type.' : 'You need to create at least one item in order to sell this ticket type.'" :count="item.count.items" :forceCount="true" />

		<app-content-box :tabs="textTabs" :readonly="$authorised('con/schedule/access', 'read')" v-if="!isNew">

			<template v-slot:event>

				<app-input-text label="Onboard Message" :autogrow="true" placeholder="Enter text..." v-model="model.text.alert_onboard" :validation="$v.model.text.alert_onboard" />
				<app-input-text label="Seated Message" :autogrow="true" placeholder="Enter text..." v-model="model.text.alert_seated" :validation="$v.model.text.alert_seated" />

			</template>

			<template v-slot:ticket>

				<app-input-toggle :warning="(model.items.length) ? false : 'Select at least one item'" v-if="model.purchase.enabled === $constants.schedule.ticket.purchase.required" label="Items" :column="true" :asString="true" :multiple="true" v-model="model.items" :validation="$v.model.items" :options="references.items" notes="Select which purchasble items for this ticket type are available via the event ticket office." />
				<app-input-text :label="model.purchase.enabled ? 'Purchase Message' : 'Claim Message'" :autogrow="true" placeholder="Enter text..." v-model="model.text.office_confirm" :validation="$v.model.text.office_confirm" />
				<app-input-text :label="model.purchase.enabled ? 'Refund Message' : 'Cancel Message'" :autogrow="true" placeholder="Enter text..." v-model="model.text.office_cancel" :validation="$v.model.text.office_cancel" />
				
			</template>

			<template v-slot:tickets>

				<app-input-text label="Ticket Note" :maxlength="100" placeholder="Enter text..." v-model="model.text.ticket_note" :validation="$v.model.text.ticket_note" />

			</template>
			
		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/schedule/access', 'read'),
			references: {
				items: []
			},
			model: {
				id: false,
				name: '',
				type: 0,
				status: 0,
				scope: 0,
				limit: {
					enabled: 0,
					amount: 1
				},
				purchase: {
					enabled: 0
				},
				capacity: {
					enabled: 0,
					amount: 0
				},
				window: {
					enabled: 0,
					from: 0,
					to: 0
				},
				text: {
					alert_onboard: '',
					alert_seated: '',
					office_confirm: '',
					office_cancel: '',
					ticket_note: ''
				}
			}
		}

	},

	validations: {
		model: {
			name: {
				required
			},
			text: {},
			items: [],
			limit: {},
			purchase: {},
			capacity: {},
			window: {}
		}
	},

	computed: {

		textTabs: function() {

			return [
				{ name: 'event', text: 'Event Page' },
				{ name: 'ticket', text: 'Ticket Office', warning: this.model.purchase.enabled === this.$constants.schedule.ticket.purchase.required && !this.model.items.length, enabled: this.model.purchase.enabled !== this.$constants.schedule.ticket.purchase.prepurchase },
				{ name: 'tickets', text: 'My Tickets' }
			]

		}

	}

}

</script>

<style scoped>

</style>